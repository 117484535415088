<template>
  <section class="main-page">
    <MainSlider />
    <div class="main-page__banner">
      <div class="main-page__banner-wrapper">
        <div class="main-page__banner-image">
          <img src="~~/assets/icons/big-logo.svg" alt="logo" />
        </div>
        <div class="main-page__banner-text">
          <h1 class="main-page__banner-title">
            {{ _T("@The voltage standard") }}
          </h1>
          <p class="main-page__banner-subtitle">
            {{ _T("@Voltage standard description") }}
          </p>
        </div>
      </div>
    </div>
    <RegularSlider />
    <div class="main-page__slider-category-block">
      <div class="main-page__slider-category-block-wrp">
        <ClientOnly>
          <SliderCategory />
        </ClientOnly>
        <div class="main-page__about-company">
          <div class="main-page__about-company-title-w">
            <h5 class="main-page__about-company-title">
              {{ _T("@About company") }}
            </h5>
            <p class="main-page__about-company-text">
              {{ _T("@History of company development") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="main-page__team-block">
      <div class="main-page__team-w">
        <p class="main-page__team-text">
          {{ _T("@LP team set itself new goal") }}
        </p>
      </div>
    </div>
    <div class="main-page__b2b-block">
      <div class="main-page__b2b-bgr">
        <img
          class="main-page__b2b-bgr-img"
          src="/img/mainB2bBckgrnd.png"
          alt="B2B портал"
          loading="lazy"
        />
      </div>
      <div class="main-page__b2b-block-container">
        <div class="main-page__b2b-logo">
          <p class="main-page__b2b-logo-title">B2B</p>
        </div>
        <div class="main-page__b2b-heading">
          <h3 class="main-page__b2b-heading-title">
            {{ _T("@Convenient portal for orders and workflow") }}
          </h3>
          <p class="main-page__b2b-heading-text">
            {{ _T("@B2B portal for wholesale buyers") }}
          </p>
        </div>
        <div class="main-page__b2b-info">
          <div class="main-page__b2b-info-item">
            <p class="main-page__b2b-info-text">
              {{ _T("@Expansion of business due new products") }}
            </p>
          </div>
          <div class="main-page__b2b-info-item">
            <p class="main-page__b2b-info-text">
              {{ _T("@Flexibility of product range") }}
            </p>
          </div>
          <div class="main-page__b2b-info-item">
            <p class="main-page__b2b-info-text">
              {{ _T("@Excellent service and comfortable conditions") }}
            </p>
          </div>
          <div class="main-page__b2b-info-item">
            <p class="main-page__b2b-info-text">
              {{ _T("@Guaranteed product quality and technical support") }}
            </p>
          </div>
        </div>
        <a
          class="main-page__b2b-link"
          href="https://b2b.logicpower.ua/"
          target="_blank"
        >
          {{ _T("@Go to B2B") }}
        </a>
      </div>
    </div>

    <div class="main-page__coop-block">
      <div class="main-page__coop-title-w">
        <h2 class="main-page__coop-title">
          {{ _T("@We cooperate") }}
        </h2>
        <p class="main-page__coop--title-text">
          ТМ LOGICPOWER – 100% {{ _T("@Manufacturer quality assurance") }}
        </p>
      </div>
      <CooperationSlider />
    </div>
    <div class="main-page__news-block">
      <BlogRecentlyPosts>
        <div class="main-page__news-title-w">
          <h2 class="main-page__news-title">
            {{ _T("@News") }}
            <span>&#38;</span>
            {{ _T("@Articles") }}
          </h2>
          <p class="main-page__news-title-text">
            {{ _T("@All you need to know about technologies") }} LOGICPOWER
          </p>
        </div>
      </BlogRecentlyPosts>
    </div>
  </section>
</template>

<script setup>
import SliderCategory from "~~/modules/home/components/SliderCategory.vue";
import CooperationSlider from "~~/modules/home/components/cooperationSlider/CooperationSlider.vue";
import MainSlider from "~~/modules/home/components/mainSlider/MainSlider.vue";
import logoImg from "~~/assets/icons/LogicPower-Logo.png";
import RegularSlider from "~/modules/home/components/regularSlider/RegularSlider.vue";
import BlogRecentlyPosts from "~/modules/blog/components/BlogRecentlyPosts.vue";

const config = useRuntimeConfig();
const route = useRoute();

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;

  @include flex-container(column, center);

  gap: 32px;
  padding-bottom: 100px;

  @include bigMobile {
    gap: 24px;
  }

  &__slider-category-block {
    padding: 24px 16px 64px;
  }

  &__slider-category-block-wrp {
    @extend %width-main;

    @include flex-container(column, stretch, center);
    gap: 64px;

    margin: auto;

    @include mobile {
      gap: 48px;
    }
  }

  &__banner {
    @extend %padding-wrp;

    @include bigMobile {
      display: none;
    }
  }

  &__banner-wrapper {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    background-color: white;

    border-radius: 16px;

    gap: 16px;
    padding: 32px;
    margin: auto;
  }

  &__banner-image {
    flex: 1 0 auto;
  }

  &__banner-text {
    max-width: 1105px;
    width: 100%;

    @include flex-container(column, center, flex-start);

    gap: 32px;
  }

  &__banner-title {
    @include font(34, 41, 600);
    color: #2b2b2b;
  }

  &__banner-subtitle {
    @include font(20, 28, 400);
    color: #2b2b2b;
    letter-spacing: 0.02em;
  }

  &__about-company {
    @extend %width-main;

    border-radius: 16px;

    color: white;
    background-color: #393d38;

    @include mobile {
      border-radius: 8px;
    }
  }

  &__about-company-title-w {
    @include flex-container;

    @include bigMobile {
      flex-direction: column;
    }
  }

  &__about-company-title {
    max-width: 347px;
    width: 100%;
    min-height: 270px;

    @include flex-container(row, center, center);

    @include font(28, 40, 600);
    text-align: center;
    letter-spacing: 0.02em;

    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;

    background-color: var(--color-primary-base);

    @include bigMobile {
      max-width: none;
      min-height: 70px;

      border-bottom-left-radius: 0;
      border-top-right-radius: 16px;
    }

    @include mobile {
      @include font(22, 26, 600);

      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  &__about-company-text {
    @include flex-container(row, center, center);
    align-self: center;

    @include font(18, 25, 500);
    letter-spacing: 0.02em;

    padding: 16px;

    @include mobile {
      @include font;

      padding: 24px 16px;
    }
  }

  // main-page__team-block
  &__team-block {
    @include flex-container(column, stretch, center);

    padding: 0 16px;

    @include mobile {
      padding: 32px 16px;
    }
  }

  &__team-w {
    @extend %width-main;

    @include flex-container(row, center, center);

    background: linear-gradient(91.75deg, #f36c21 0.14%, #f5821f 99.6%);
    border-radius: 16px;

    padding: 32px;

    @include mobile {
      border-radius: 8px;
      padding: 16px 8px;
    }
  }

  &__team-text {
    width: 100%;
    max-width: 1248px;

    @include font(18, 25, 500);
    letter-spacing: 0.02em;

    color: white;

    @include mobile {
      @include font;
    }
  }

  // main-page__b2b-block
  &__b2b-block {
    position: relative;

    @include flex-container(column, stretch, center);

    padding: 32px 16px;
  }

  &__b2b-bgr {
    position: absolute;
    @include setAbs;
  }

  &__b2b-bgr-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__b2b-block-container {
    position: relative;

    @extend %width-main;

    @include flex-container(column, stretch, center);
    gap: 48px;

    @include mobile {
      gap: 32px;
    }
  }

  &__b2b-logo {
    max-width: 200px;
    width: 100%;
    height: 200px;

    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: var(--color-primary-base);

    @include mobile {
      max-width: 100px;
      height: 100px;
    }
  }

  &__b2b-logo-title {
    @include font(34, 41, 600);
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: white;

    @include mobile {
      @include font(24, 31, 700);
    }
  }

  &__b2b-heading {
    @include flex-container(column, stretch, center);
    gap: 32px;

    color: white;
  }

  &__b2b-heading-title {
    @include font(32, 42, 600);
    text-align: center;

    @include mobile {
      @include font(16, 22, 400);
    }
  }

  &__b2b-heading-text {
    @include font(20, 30, 600);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    text-align: center;

    @include mobile {
      @include font(16, 20, 600);
    }
  }

  &__b2b-info {
    width: 100%;

    @include flex-container;
    gap: 32px;

    @include bigMobile {
      flex-direction: column;
      align-items: center;

      gap: 24px;
    }
  }

  &__b2b-info-item {
    width: 100%;
    max-width: 343px;
    min-height: 237px;

    @include flex-container(column, flex-start, center);

    background-color: #f3f3f3;
    border-radius: 8px;

    padding: 16px;

    @include bigMobile {
      max-width: 643px;
      min-height: auto;
    }
  }

  &__b2b-info-text {
    @include font;
    letter-spacing: 0.02em;
  }

  &__b2b-link {
    width: 100%;
    max-width: 363px;

    @include flex-container(row, center, center);

    @include font(20, 30, 600);
    text-transform: uppercase;

    color: white;
    background-color: var(--color-primary-base);
    border-radius: 6px;

    padding: 8px 0;
    transition: 0.2s ease-in-out;

    @include mobile {
      max-width: 230px;
    }

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }

  // main-page__coop-block
  &__coop-block {
    @include flex-container(column, flex-start, center);
    gap: 64px;

    @extend %padding-wrp;

    @include mobile {
      min-height: 330px;

      gap: 48px;
    }
  }

  &__coop-title-w {
    width: 100%;

    @include flex-container(column, stretch, center);
    gap: 24px;
  }

  &__coop-title {
    @include font(32, 42, 600);
    text-align: center;

    @include mobile {
      @include font(22, 26, 600);
    }
  }

  &__coop--title-text {
    @include font(20, 30, 600);
    text-align: center;
    text-transform: uppercase;

    @include mobile {
      @include font(18, 22, 600);
    }
  }

  //main-page__news-block
  &__news-block {
    width: 100%;

    @include flex-container(column, center, center);
    gap: 64px;

    background-color: white;

    padding: 0 16px 32px 16px;

    @include mobile {
      gap: 48px;
    }
  }

  &__news-title-w {
    width: 100%;

    @include flex-container(column, stretch, center);
    gap: 24px;

    padding-top: 64px;

    @include mobile {
      gap: 16px;

      padding-top: 40px;
    }
  }

  &__news-title {
    @include font(32, 40, 600);
    text-align: center;
    text-transform: capitalize;

    & span {
      color: var(--color-primary-base);
    }

    @include mobile {
      @include font(22, 26, 600);
    }
  }

  &__news-title-text {
    @include font(20, 30, 600);
    text-align: center;
    text-transform: uppercase;

    @include mobile {
      @include font(18, 22, 500);
    }
  }
}
</style>
