<template>
  <div ref="selector" class="category-slider-selector">
    <div v-if="!isMobile" class="category-slider">
      <div class="category-slider__prod">
        <div class="category-slider__prod-title-w">
          <h3 class="category-slider__prod-title">
            {{ _T("@Products") }}
          </h3>
          <div class="category-slider__prod-line" />
        </div>
        <div class="category-slider__prod-list-w">
          <div class="category-slider__prod-arrows">
            <div class="category-slider__prod-arrow-up" @click="prevSlide">
              <span />
            </div>
            <div class="category-slider__prod-arrow-down" @click="nextSlide">
              <span />
            </div>
          </div>
          <div ref="leftWrapper" class="category-slider__prod-list-container">
            <ul
              ref="linkListEl"
              class="category-slider__prod-list"
              :style="{ '--translate-y': linksTranslateY + 'px' }"
            >
              <li
                v-for="(category, index) in categories"
                :key="index"
                ref="linkItems"
                class="category-slider__prod-item"
                :class="{ 'prod-item-active': index === currentSlide }"
                @click="goToSlide(index)"
              >
                {{ category.getName() }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="category-slider__category">
        <div
          class="category-slider__category-w"
          :style="{ '--translate-y': TranslateY() + '%' }"
        >
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="category-slider__category-item-w"
          >
            <div class="category-slider__category-img">
              <img
                :src="category.getImageUrl('slider')"
                :alt="category.getName()"
                loading="lazy"
              />
            </div>
            <div class="category-slider__category-title-w">
              <h4 class="category-slider__category-title">
                {{ category.getName() }}
              </h4>
              <p class="category-slider__category-description">
                {{ getCategoryContent(category, "shortDescription") }}
              </p>
              <MyLink
                class="category-slider__category-link"
                name="category"
                :params="{ categorySlug: category.slug }"
              >
                {{ _T("@Go to section") }}
              </MyLink>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="category-list">
      <h3 class="category-list__title">
        {{ _T("@Products") }}
      </h3>
      <div class="category-list__container" :class="{ show: showAll }">
        <MyLink
          v-for="category in categories"
          class="category-list__item"
          name="category"
          :params="{ categorySlug: category.slug }"
        >
          <div class="category-list__item-img-w">
            <img
              class="category-list__item-img"
              :src="category.getImageUrl('slider')"
              :alt="category.getName()"
            />
          </div>
          <h4 class="category-list__item-title">
            {{ category.getName() }}
          </h4>
        </MyLink>
      </div>
      <div class="category-list__link" @click.prevent="showAll = !showAll">
        <img
          class="category-list__link-img"
          src="~~/assets/icons/eye-show.svg"
          alt="eye-empty"
        />
        <span class="category-list__link-text">
          {{ showAll ? _T("@Hide") : _T("@All categories") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCategoriesStore } from "~~/store/categoriesStore";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";
import { addScopedEventListener } from "~~/utils/eventListener";

const languageStore = useLanguageFilterStore();
const { getUserLanguage: lang } = storeToRefs(languageStore);

const categoriesStore = useCategoriesStore();
const { getTopLevelCategories: categories, getCategoryContent } =
  storeToRefs(categoriesStore);

const leftWrapper = ref(null);
const linkListEl = ref(null);
const linkItems = ref(null);
const selector = ref(null);

const currentSlide = ref(0);
const linksTranslateY = ref(0);
const isMobile = ref(false);
const showAll = ref(false);

const slideCount = categories.value.length;

function step() {
  return 100 / slideCount;
}

function TranslateY() {
  return currentSlide.value * -step();
}

function calcHeight() {
  const leftWrapRect = leftWrapper.value.getBoundingClientRect();
  const linkListRect = linkListEl.value.getBoundingClientRect();
  const activeLinkRect =
    linkItems.value[currentSlide.value].getBoundingClientRect();
  const linkBottomFromWrapperCenter =
    leftWrapRect.height / 2 - (activeLinkRect.bottom - leftWrapRect.top);

  if (linksTranslateY.value + linkBottomFromWrapperCenter > 0) {
    linksTranslateY.value = 0;
  } else if (
    linksTranslateY.value + linkBottomFromWrapperCenter <
    leftWrapRect.height - linkListRect.height
  ) {
    linksTranslateY.value = leftWrapRect.height - linkListRect.height;
  } else {
    linksTranslateY.value += linkBottomFromWrapperCenter;
  }
}

function prevSlide() {
  if (currentSlide.value - 1 < 0) {
    currentSlide.value = slideCount - 1;
  } else {
    currentSlide.value = currentSlide.value - 1;
  }

  calcHeight();
}

function nextSlide() {
  if (currentSlide.value + 1 >= slideCount) {
    currentSlide.value = 0;
  } else {
    currentSlide.value = currentSlide.value + 1;
  }

  calcHeight();
}

function goToSlide(index) {
  currentSlide.value = index;
  calcHeight();
}

function calcIsMobile() {
  const mobWidth = getComputedStyle(selector.value).getPropertyValue(
    "--mobile-width",
  );
  isMobile.value = window.innerWidth <= parseInt(mobWidth);
}

function onResize() {
  calcIsMobile();
}

onMounted(() => {
  onResize();
  addScopedEventListener(window, "resize", onResize);
});
</script>

<style lang="scss" scoped>
.category-slider-selector {
  width: 100%;

  @include flex-container(column, center, center);
  --mobile-width: #{$mobile-big-width};
}
.category-slider {
  @include flex-container(row, center);
  @extend %width-main;

  height: 540px;

  border-radius: 16px;

  overflow: hidden;

  &__prod {
    max-width: 415px;
    width: 100%;

    @include flex-container(column, center);
    flex-shrink: 2;
    flex-grow: 1;

    color: white;
    background-color: #393d38;

    padding: 16px 0;
  }

  &__prod-title {
    @include font(20, 30, 600);
    text-align: center;
    text-transform: uppercase;

    margin-bottom: 16px;
  }

  &__prod-line {
    width: 100%;
    height: 1px;

    background-color: white;
  }

  &__prod-list-w {
    position: relative;

    padding: 80px 16px;
  }

  &__prod-arrows {
    position: absolute;
    @include setAbs;
    z-index: 3;

    @include flex-container(column, space-between, center);

    padding-top: 16px;
  }

  &__prod-arrow-up {
    width: 48px;
    height: 48px;

    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: #ffffff;
    @extend %shadow;

    transition: 0.2s ease-in-out;
    cursor: pointer;

    & span {
      width: 10px;
      height: 10px;

      border-top: 1px solid black;
      border-left: 1px solid black;

      transform: rotateZ(45deg) translate(2px, 2px);
    }
  }

  &__prod-arrow-down {
    width: 48px;
    height: 48px;

    @include flex-container(row, center, center);

    border-radius: 50%;
    background-color: #ffffff;
    @extend %shadow;

    transition: 0.2s ease-in-out;
    cursor: pointer;

    & span {
      width: 10px;
      height: 10px;

      border-top: 1px solid black;
      border-left: 1px solid black;

      transform: rotateZ(225deg) translate(2px, 2px);
    }
  }

  &__prod-list-container {
    width: 100%;
    height: 292px;

    overflow: hidden;
  }

  &__prod-list {
    --translate-y: 0;

    position: relative;
    z-index: 3;

    @include flex-container(column, center, flex-start);
    gap: 16px;

    transition: 0.3s ease-in-out;
    transform: translateY(var(--translate-y));
  }

  &__prod-item {
    @include font(20, 28);
    letter-spacing: 0.02em;

    cursor: pointer;

    &.prod-item-active {
      color: var(--color-primary-base);
    }
  }

  &__category {
    width: 100%;
    height: 540px;

    @include flex-container(column, flex-start, center);
    flex-shrink: 1;
    flex-grow: 2;

    background-color: white;

    padding: 0 32px;

    overflow: hidden;
  }

  &__category-w {
    --translate-y: 0;

    transform: translateY(var(--translate-y));

    transition: 0.3s ease-in-out;
  }

  &__category-item-w {
    width: 100%;
    height: 540px;

    @include flex-container(column, space-around, center);
  }

  &__category-img {
    max-width: 341px;
    width: 100%;
  }

  &__category-title-w {
    width: 100%;
    max-width: 920px;

    @include flex-container(column, center, flex-start);
    gap: 16px;
  }

  &__category-title {
    width: 100%;

    @include font(20, 30, 600);
    letter-spacing: 0.02em;
    color: #2b2b2b;
    text-align: start;
    text-transform: uppercase;
  }

  &__category-description {
    @include font(16, 22, 400);
    color: #2b2b2b;
    letter-spacing: 0.02em;

    @include lineClamp(3);

    margin-bottom: 16px;
  }

  &__category-link {
    width: 100%;
    max-width: 376px;

    @include flex-container(row, center, center);

    @include font(20, 30, 600);
    text-transform: uppercase;
    color: white;

    border-radius: 8px;
    background-color: var(--color-primary-base);

    padding: 8px 0;

    transition: 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-dark);
    }

    &:active {
      background-color: #ac450b;
    }
  }
}
.category-list {
  width: 100%;

  --gap: 16px;

  @include flex-container(column, center, center);

  gap: calc(var(--gap) * 2);

  &__title {
    @include font(22, 26, 600);
    text-align: center;
    text-transform: uppercase;

    @include bigMobile {
      text-transform: none;
    }
  }

  &__link {
    width: 100%;
    max-width: 383px;

    @include flex-container(row, center, center);
    gap: var(--gap);

    background-color: white;

    border-radius: 8px;
    @extend %shadow;

    padding: 10px 0;
  }

  &__link-img {
    @include fixedHW(18px, 18px);
  }

  &__link-text {
    @include font(20, 28);
    letter-spacing: 0.02em;
    text-align: center;
  }

  &__container {
    width: 100%;

    @include flex-container(row, center);
    flex-wrap: wrap;
    gap: var(--gap);

    @include bigMobile {
      &.show {
        .category-list__item {
          @include flex-container(column, space-between);
        }
      }
    }
  }
  &__item {
    @include flex-container(column, center, center);
    gap: calc(var(--gap) * 2);

    @include set-item-count-in-row(2);
    background-color: white;

    border-radius: 8px;
    @extend %shadow;

    padding: 8px;

    transition: 0.2s ease-in-out;

    @include bigMobile {
      display: none;

      &:nth-child(-n + 6) {
        display: flex;
      }
    }

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__item-img-w {
    width: 100%;

    position: relative;
  }

  &__item-img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    pointer-events: none;
  }

  &__item-title {
    @include font(16, 19, 500);
    color: #2b2b2b;
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;
  }
}
</style>
